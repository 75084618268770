import React from 'react';

const Hazardous = ({ width, height, fill }) => {
  return (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className="iconify iconify--emojione-monotone">
      <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30c16.568 0 30-13.432 30-30S48.568 2 32 2M4.754 35.687l8.567 2.132c-.037.404-.073.809-.073 1.252a19.6 19.6 0 002.408 9.431l-4.314 1.622a27.419 27.419 0 01-6.588-14.437M32 59.5c-6.852 0-13.121-2.524-17.942-6.686l3.576-1.365c3.442 4.225 8.602 6.918 14.366 6.918s10.924-2.693 14.367-6.918l3.576 1.365C45.123 56.975 38.854 59.5 32 59.5M15.749 39.07c0-5.791 3.736-6.954 9.763-6.954 1.105 0 2.225.038 3.322.075a90.33 90.33 0 006.332 0c1.1-.037 2.219-.075 3.322-.075 6.029 0 9.764 1.163 9.764 6.954 0 9.262-7.291 16.797-16.252 16.797S15.749 48.332 15.749 39.07m36.909 11.053l-4.314-1.622a19.6 19.6 0 002.408-9.431c0-.443-.035-.848-.072-1.252l8.566-2.132a27.389 27.389 0 01-6.588 14.437m-2.861-15.691c-2.332-4.8-8.381-4.815-11.309-4.815-1.131 0-2.279.038-3.406.077a87.921 87.921 0 01-6.162 0 99.008 99.008 0 00-3.408-.077c-2.928 0-8.976.016-11.308 4.815l-9.702-2.398L4.5 32c0-5.909 1.88-11.385 5.064-15.873.367.502.975.945 1.232.72a14.23 14.23 0 0111.691-3.133c.58.113 1.092-2.021.389-2.156a16.403 16.403 0 00-11.357 2.116C16.559 8.048 23.871 4.5 32 4.5c8.275 0 15.703 3.679 20.748 9.481a16.404 16.404 0 00-11.623-2.273c-.703.135-.193 2.27.387 2.156 4.168-.748 8.457.4 11.691 3.133.271.235.931-.262 1.288-.792A27.331 27.331 0 0159.5 32v.033l-9.703 2.399" />
      <path d="M37.083 20.053c-.764.264-1.34.296-1.242.736 2.01 8.772 10.205 10.966 16.334 4.377.305-.333-.178-.648-.707-1.262-4.942 2.115-11.463.049-14.385-3.851M26.919 20.051c-3.221 4.3-9.902 5.772-14.383 3.855-.529.611-1.014.927-.709 1.26 6.129 6.592 14.322 4.395 16.336-4.377.096-.44-.478-.473-1.244-.738" />
    </svg>
  );
};

export default Hazardous;
