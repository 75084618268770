export const LOAD_ALL_USER_ROLES_SUCCESS = 'LOAD_ALL_USER_ROLES_SUCCESS';
export const LOAD_ALL_USER_ROLES_FAILURE = 'LOAD_ALL_USER_ROLES_FAILURE';

export const LOAD_CURRENT_USER_ROLE_SUCCESS = 'LOAD_CURRENT_USER_ROLE_SUCCESS';
export const LOAD_CURRENT_USER_ROLE_FAILURE = 'LOAD_CURRENT_USER_ROLE_FAILURE';

export const LOAD_CURRENT_USER_NETWORKS_SUCCESS = 'LOAD_CURRENT_USER_NETWORKS_SUCCESS';
export const LOAD_CURRENT_USER_NETWORKS_FAILURE = 'LOAD_CURRENT_USER_NETWORKS_FAILURE';

export const LOAD_CURRENT_NETWORK_SUCCESS = 'LOAD_CURRENT_NETWORK_SUCCESS';
export const LOAD_CURRENT_NETWORK_FAILURE = 'LOAD_CURRENT_NETWORK_FAILURE';

export const LOAD_NETWORK_USERS_SUCCESS = 'LOAD_NETWORK_USERS_SUCCESS';
export const LOAD_NETWORK_USERS_FAILURE = 'LOAD_NETWORK_USERS_FAILURE';

export const LOAD_AVAILABLE_USERS_SUCCESS = 'LOAD_AVAILABLE_USERS_SUCCESS';
export const LOAD_AVAILABLE_USERS_FAILURE = 'LOAD_AVAILABLE_USERS_FAILURE';

export const LOAD_ROLES_SUMMARY_SUCCESS = 'LOAD_ROLES_SUMMARY_SUCCESS';
export const LOAD_ROLES_SUMMARY_FAILURE = 'LOAD_ROLES_SUMMARY_FAILURE';

export const LOAD_GROUPS_SUMMARY_SUCCESS = 'LOAD_GROUPS_SUMMARY_SUCCESS';
export const LOAD_GROUPS_SUMMARY_FAILURE = 'LOAD_GROUPS_SUMMARY_FAILURE';
