export const LOAD_COMBINED_GRIDS_AND_COHORTS_SUMMARY_SUCCESS =
  'LOAD_COMBINED_GRIDS_AND_COHORTS_SUMMARY_SUCCESS';
export const LOAD_COMBINED_GRIDS_AND_COHORTS_SUMMARY_FAILURE =
  'LOAD_COMBINED_GRIDS_AND_COHORTS_SUMMARY_FAILURE';

export const LOAD_ACTIVE_GRID_SUCCESS = 'LOAD_ACTIVE_GRID_SUCCESS';
export const LOAD_ACTIVE_GRID_FAILURE = 'LOAD_ACTIVE_GRID_FAILURE';

export const LOAD_ACTIVE_GRID_DETAILS_SUCCESS = 'LOAD_ACTIVE_GRID_DETAILS_SUCCESS';
export const LOAD_ACTIVE_GRID_DETAILS_FAILURE = 'LOAD_ACTIVE_GRID_DETAILS_FAILURE';

export const LOAD_ACTIVE_COHORT_SUCCESS = 'LOAD_ACTIVE_COHORT_SUCCESS';
export const LOAD_ACTIVE_COHORT_FAILURE = 'LOAD_ACTIVE_COHORT_FAILURE';

export const LOAD_ACTIVE_COHORT_DETAILS_SUCCESS = 'LOAD_ACTIVE_COHORT_DETAILS_SUCCESS';
export const LOAD_ACTIVE_COHORT_DETAILS_FAILURE = 'LOAD_ACTIVE_COHORT_DETAILS_FAILURE';

export const ADD_POLYGON_SHAPE_SUCCESS = 'ADD_POLYGON_SHAPE_SUCCESS';
export const ADD_POLYGON_SHAPE_FAILURE = 'ADD_POLYGON_SHAPE_FAILURE';

export const LOAD_ALL_GRIDS_SUCCESS = 'LOAD_ALL_GRIDS_SUCCESS';
export const LOAD_ALL_GRIDS_FAILURE = 'LOAD_ALL_GRIDS_FAILURE';

export const LOAD_ALL_COHORTS_SUCCESS = 'LOAD_ALL_COHORTS_SUCCESS';
export const LOAD_ALL_COHORTS_FAILURE = 'LOAD_ALL_COHORTS_FAILURE';

export const LOAD_GRIDS_SUMMARY_SUCCESS = 'LOAD_GRIDS_SUMMARY_SUCCESS';
export const LOAD_GRIDS_SUMMARY_FAILURE = 'LOAD_GRIDS_SUMMARY_FAILURE';

export const LOAD_COHORTS_SUMMARY_SUCCESS = 'LOAD_COHORTS_SUMMARY_SUCCESS';
export const LOAD_COHORTS_SUMMARY_FAILURE = 'LOAD_COHORTS_SUMMARY_FAILURE';
