// contains all the action creator functions.
export const LOAD_ALL_DEVICES_SUCCESS = 'LOAD_ALL_DEVICES_SUCCESS';
export const LOAD_ALL_DEVICES_FAILURE = 'LOAD_ALL_DEVICES_FAILURE';

export const LOAD_MAINTENANCE_LOGS_SUCCESS = 'LOAD_MAINTENANCE_LOGS_SUCCESS';
export const LOAD_MAINTENANCE_LOGS_FAILURE = 'LOAD_MAINTENANCE_LOGS_FAILURE';

export const UPDATE_SINGLE_DEVICE_SUCCESS = 'UPDATE_SINGLE_DEVICE_SUCCESS';
export const UPDATE_SINGLE_DEVICE_ERROR = 'UPDATE_SINGLE_DEVICE_ERROR';

export const RESET_DEVICE_SUCCESS = 'RESET_DEVICE_SUCCESS';
export const RESET_DEVICE_COMPONENTS_SUCCESS = 'RESET_DEVICE_COMPONENTS_SUCCESS';
export const RESET_MAINTENANCE_LOGS = 'RESET_MAINTENANCE_LOGS';

export const INSERT_MAINTENANCE_LOGS_SUCCESS = 'INSERT_MAINTENANCE_LOGS_SUCCESS';
export const INSERT_MAINTENANCE_LOGS_FAILURE = 'INSERT_MAINTENANCE_LOGS_FAILURE';

export const UPDATE_SINGLE_MAINTENANCE_LOGS_SUCCESS = 'UPDATE_SINGLE_MAINTENANCE_LOGS_SUCCESS';
export const UPDATE_SINGLE_MAINTENANCE_LOGS_FAILURE = 'UPDATE_SINGLE_MAINTENANCE_LOGS_FAILURE';

export const DELETE_SINGLE_MAINTENANCE_LOGS_SUCCESS = 'DELETE_SINGLE_MAINTENANCE_LOGS_SUCCESS';
export const DELETE_SINGLE_MAINTENANCE_LOGS_FAILURE = 'DELETE_SINGLE_MAINTENANCE_LOGS_FAILURE';

export const LOAD_DEVICE_UPTIME_SUCCESS = 'LOAD_DEVICE_UPTIME_SUCCESS';
export const LOAD_DEVICE_UPTIME_FAILURE = 'LOAD_DEVICE_UPTIME_FAILURE';

export const LOAD_DEVICE_BATTERY_VOLTAGE_SUCCESS = 'LOAD_DEVICE_BATTERY_VOLTAGE_SUCCESS';
export const LOAD_DEVICE_BATTERY_VOLTAGE_FAILURE = 'LOAD_DEVICE_BATTERY_VOLTAGE_FAILURE';

export const LOAD_DEVICE_SENSOR_CORRELATION_SUCCESS = 'LOAD_DEVICE_SENSOR_CORRELATION_SUCCESS';
export const LOAD_DEVICE_SENSOR_CORRELATION_FAILURE = 'LOAD_DEVICE_SENSOR_CORRELATION_FAILURE';

export const INSERT_NEW_DEVICE_SUCCESS = 'INSERT_NEW_DEVICE_SUCCESS';
export const INSERT_NEW_DEVICE_FAILURE = 'INSERT_NEW_DEVICE_FAILURE';
