// contains all the action creator functions.
export const LOAD_USER_DEFAULT_GRAPHS_SUCCESS = 'LOAD_USER_DEFAULT_GRAPHS_SUCCESS';
export const LOAD_USER_DEFAULT_GRAPHS_ERROR = 'LOAD_USER_DEFAULT_GRAPHS_ERROR';

export const SET_USER_DEFAULTS_GRAPHS_SUCCESS = 'SET_USER_DEFAULTS_GRAPHS_SUCCESS';
export const SET_USER_DEFAULTS_GRAPHS_ERROR = 'SET_USER_DEFAULTS_GRAPHS_ERROR';

export const UPDATE_USER_DEFAULT_GRAPHS_SUCCESS = 'UPDATE_USER_DEFAULT_GRAPHS_SUCCESS';
export const UPDATE_USER_DEFAULT_GRAPHS_FAILURE = 'UPDATE_USER_DEFAULT_GRAPHS_FAILURE';

export const RESET_LOCATION_FILTER_SUCCESS = 'RESET_LOCATION_FILTER_SUCCESS';
export const RESET_USER_GRAPH_DEFAULTS_SUCCESS = 'RESET_USER_GRAPH_DEFAULTS_SUCCESS';

export const LOAD_DASHBOARD_SITES_SUCCESS = 'LOAD_DASHBOARD_SITES_SUCCESS';

export const LOAD_DASHBOARD_SITES_FAILURE = 'LOAD_DASHBOARD_SITES_FAILURE';
