export const LOAD_DEVICES_STATUS_SUCCESS = 'LOAD_DEVICE_STATUS_SUCCESS';
export const LOAD_DEVICES_STATUS_FAILURE = 'LOAD_DEVICE_STATUS_FAILURE';

export const LOAD_NETWORK_UPTIME_SUCCESS = 'LOAD_NETWORK_UPTIME_SUCCESS';
export const LOAD_NETWORK_UPTIME_FAILURE = 'LOAD_NETWORK_UPTIME_FAILURE';

export const LOAD_ALL_DEVICES_UPTIME_SUCCESS = 'LOAD_ALL_DEVICES_UPTIME_SUCCESS';
export const LOAD_ALL_DEVICES_UPTIME_FAILURE = 'LOAD_ALL_DEVICES_UPTIME_FAILURE';

export const LOAD_SINGLE_UPTIME_SUCCESS = 'LOAD_SINGLE_UPTIME_SUCCESS';
export const LOAD_SINGLE_UPTIME_FAILURE = 'LOAD_SINGLE_UPTIME_FAILURE';

export const LOAD_UPTIME_LEADERBOARD_SUCCESS = 'LOAD_UPTIME_LEADERBOARD_SUCCESS';

export const LOAD_UPTIME_LEADERBOARD_FAILURE = 'LOAD_UPTIME_LEADERBOARD_FAILURE';

export const LOAD_MANAGEMENT_DEVICES_SUCCESS = 'LOAD_MANAGEMENT_DEVICES_SUCCESS';
export const LOAD_MANAGEMENT_DEVICES_FAILURE = 'LOAD_MANAGEMENT_DEVICES_FAILURE';

export const LOAD_FILTERED_DEVICES_SUCCESS = 'LOAD_FILTERED_DEVICES_SUCCESS';
export const LOAD_FILTERED_DEVICES_FAILURE = 'LOAD_FILTERED_DEVICES_FAILURE';

export const LOAD_ACTIVE_FILTERS_SUCCESS = 'LOAD_ACTIVE_FILTERS_SUCCESS';
export const LOAD_ACTIVE_FILTERS_FAILURE = 'LOAD_ACTIVE_FILTERS_FAILURE';

export const LOAD_AIRQLOUD_UPTIME_SUCCESS = 'LOAD_AIRQLOUD_UPTIME_SUCCESS';
export const LOAD_AIRQLOUD_UPTIME_FAILURE = 'LOAD_AIRQLOUD_UPTIME_FAILURE';
